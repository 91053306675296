var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-container" },
    [
      _c("Form", {
        attrs: { column: _vm.column, query: _vm.query },
        on: {
          createRandom: _vm.createRandom,
          handleCheckLink: _vm.handleCheckLink,
          handleImageUrl: _vm.handleImageUrl,
        },
        scopedSlots: _vm._u([
          {
            key: "more",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10",
                    attrs: { size: "medium", type: "primary" },
                    on: { click: _vm.handleCreate },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("新增一组")) + " ")]
                ),
                _vm._l(_vm.query.extra_info, function (item, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c(
                        "el-row",
                        { staticClass: "row-bg", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "m-r-10 m-b-10",
                              attrs: { span: 3 },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.translateTitle("请输入标题"),
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "m-r-10 m-b-10",
                              attrs: { span: 6 },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: _vm.translateTitle("请输入内容"),
                                },
                                model: {
                                  value: item.content,
                                  callback: function ($$v) {
                                    _vm.$set(item, "content", $$v)
                                  },
                                  expression: "item.content",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "m-r-10 m-b-10",
                              attrs: { span: 6 },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder:
                                    _vm.translateTitle("请输入跳转(选填)"),
                                },
                                model: {
                                  value: item.url,
                                  callback: function ($$v) {
                                    _vm.$set(item, "url", $$v)
                                  },
                                  expression: "item.url",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.hanldeRemove(i)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.translateTitle("删除")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "activity-btn" },
        [
          _vm.query.create_time < _vm.time && _vm.query.create_time
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRelease("save")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
              )
            : _vm._e(),
          _vm.isBtn
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRelease("now")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("立即发布")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-pie-chart",
                      size: "medium",
                      type: "primary",
                    },
                    on: { click: _vm.hanldeTiming },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("定时发布")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRelease("null")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("存为草稿")) + " ")]
                ),
              ]
            : _vm._e(),
          _vm.query.publish_time > _vm.time
            ? _c("div", { staticClass: "activity-tips" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.translateTitle("内容已被设置为定时发布，发布时间")
                    ) +
                    "：" +
                    _vm._s(
                      _vm._f("dateFormat")(_vm.query.publish_time / 1000, true)
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c("ReleaseDialog", {
        ref: "releaseDialog",
        on: { handleRelease: _vm.handleRelease },
      }),
      _c("CheckLinkDialog", {
        ref: "checklink",
        on: { handleLink: _vm.handleLink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }