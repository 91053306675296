<script>
  import Common from '../common.vue'
  import createAutoClick from '@/utils/random'
  export default {
    components: {
      Common,
    },
    data() {
      return {
        query: {
          id: null,
          type: 1,
          title: null,
          cover_pic: null,
          label: '',
          start_time: null,
          finish_time: null,
          join_start_time: null,
          join_finish_time: null,
          type_value: null,
          auto_click: null,
          click_show: null,
          extra_info: [],
          is_open: -1,
          form_info: [],
          remark_content: null,
          rank_time: null,
          publish_time: null,
          link: null,
          recommend: null,
          is_pay: -1,
          pay_money: null,
          content: null,
          create_time: null,
        },
        readNumList: [],
      }
    },
    methods: {
      // 生成随机数
      createRandom() {
        this.query.auto_click = createAutoClick()
        this.readNumList = createAutoClick().split(',')
      },
      handleImageUrl(prop, data) {
        this.query[prop] = data
      },
      // 新增更多说明
      handleCreate() {
        this.query.extra_info.push({
          title: null,
          content: null,
          url: null,
        })
      },
      // 删除
      hanldeRemove(index) {
        this.query.extra_info.splice(index, 1)
      },
      // 地址
      handleLink(link) {
        this.query.type_value = link
      },
    },
  }
</script>
