const config = {
  time: new Date().getTime(),
  labelList: [],
  timeKey: Object.freeze([
    'create_time',
    'join_finish_time',
    'join_start_time',
    'rank_time',
    'recommend',
    'start_time',
    'finish_time',
  ]),
  checkItem: Object.freeze(['phone', 'name', 'weixin', 'qq', 'remark']),
  activityTypeList: Object.freeze([
    {
      id: 1,
      label: '默认',
    },
    {
      id: 2,
      label: '其他',
    },
  ]),
  signUpList: Object.freeze([
    {
      id: 'phone',
      disabled: true,
      label: '手机号',
    },
    {
      id: 'name',
      label: '姓名',
    },
    {
      id: 'weixin',
      label: '微信',
    },
    {
      id: 'qq',
      label: 'QQ',
    },
    {
      id: 'remark',
      label: '备注',
    },
  ]),
  openRedPacketList: Object.freeze([
    {
      id: 1,
      label: '开启',
    },
    {
      id: -1,
      label: '关闭',
    },
  ]),
}

export default config
