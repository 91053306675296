<template>
  <div class="activity-container">
    <Form
      :column="column"
      :query="query"
      @createRandom="createRandom"
      @handleCheckLink="handleCheckLink"
      @handleImageUrl="handleImageUrl"
    >
      <template #more>
        <el-button
          class="m-b-10"
          size="medium"
          type="primary"
          @click="handleCreate"
        >
          {{ translateTitle('新增一组') }}
        </el-button>
        <div v-for="(item, i) in query.extra_info" :key="i">
          <el-row class="row-bg" type="flex">
            <el-col class="m-r-10 m-b-10" :span="3">
              <el-input
                v-model="item.title"
                clearable
                :placeholder="translateTitle('请输入标题')"
              />
            </el-col>
            <el-col class="m-r-10 m-b-10" :span="6">
              <el-input
                v-model="item.content"
                clearable
                :placeholder="translateTitle('请输入内容')"
              />
            </el-col>
            <el-col class="m-r-10 m-b-10" :span="6">
              <el-input
                v-model="item.url"
                clearable
                :placeholder="translateTitle('请输入跳转(选填)')"
              />
            </el-col>
            <el-col :span="6">
              <el-button type="text" @click="hanldeRemove(i)">
                {{ translateTitle('删除') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </template>
    </Form>
    <el-divider />
    <div class="activity-btn">
      <el-button
        v-if="query.create_time < time && query.create_time"
        size="medium"
        type="primary"
        @click="handleRelease('save')"
      >
        {{ translateTitle('保存') }}
      </el-button>
      <template v-if="isBtn">
        <el-button size="medium" type="primary" @click="handleRelease('now')">
          {{ translateTitle('立即发布') }}
        </el-button>
        <el-button
          icon="el-icon-pie-chart"
          size="medium"
          type="primary"
          @click="hanldeTiming"
        >
          {{ translateTitle('定时发布') }}
        </el-button>
        <el-button size="medium" @click="handleRelease('null')">
          {{ translateTitle('存为草稿') }}
        </el-button>
      </template>
      <div v-if="query.publish_time > time" class="activity-tips">
        {{ translateTitle('内容已被设置为定时发布，发布时间') }}：{{
          (query.publish_time / 1000) | dateFormat(true)
        }}
      </div>
    </div>
    <!-- 定时发布--对话框 -->
    <ReleaseDialog ref="releaseDialog" @handleRelease="handleRelease" />
    <!-- 跳转地址--对话框 -->
    <CheckLinkDialog ref="checklink" @handleLink="handleLink" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Form from '@/components/Form'
  import ReleaseDialog from '@/components/ReleaseDialog'
  import CheckLinkDialog from '@/components/JumpLink/checkLinkDialog.vue'
  import { activitySave } from '@/api/operate'
  import config from './config'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
      ReleaseDialog,
      CheckLinkDialog,
    },
    props: {
      query: {
        type: Object,
        default: () => ({}),
      },
      readNumList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      isBtn() {
        return (
          !this.query.publish_time ||
          !this.query.id ||
          this.query.publish_time > this.time
        )
      },
      column() {
        return [
          {
            label: '活动类型',
            prop: 'type',
            type: 'radio',
            list: this.activityTypeList,
            isHide: this.query.id,
          },
          {
            label: '活动标题',
            prop: 'title',
            tips: '建议标题字数在18-25个字之间',
          },
          {
            label: '活动封面',
            prop: 'cover_pic',
            type: 'uploadImage',
            width: 130,
            height: 80,
            eventName: 'handleImageUrl',
            tips: '建议尺寸580x290px',
          },
          {
            label: '标签',
            prop: 'label',
            type: 'radio',
            list: this.labelList,
          },
          {
            label: '活动开始时间',
            prop: 'start_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '活动结束时间',
            prop: 'finish_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
          {
            label: '报名开始时间',
            prop: 'join_start_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
            isHide: this.query.type != 1,
          },
          {
            label: '报名结束时间',
            prop: 'join_finish_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
            isHide: this.query.type != 1,
          },
          {
            label: '跳转链接',
            prop: 'type_value',
            tips: '设置活动列表目标跳转页',
            clickText: '选择跳转地址',
            clickEventName: 'handleCheckLink',
            isHide: this.query.type != 2,
          },
          {
            label: '虚拟阅读数',
            prop: 'auto_click',
            clickText: this.translateTitle('重新生成'),
            clickEventName: 'createRandom',
            tips: this.query.create_time
              ? `${this.translateTitle('当前显示阅读数为：')}${
                  this.query.click_show
                }`
              : '',
          },
          {
            type: 'slot',
            slotName: 'more',
            label: '更多说明',
            isHide: this.query.type != 1,
          },
          {
            label: '活动报名',
            prop: 'is_open',
            type: 'radio',
            list: this.openRedPacketList,
            isHide: this.query.type != 1,
          },
          {
            label: '报名必填项目',
            prop: 'form_info',
            type: 'checkbox',
            list: this.signUpList,
            isHide: this.query.type != 1,
          },
          {
            label: '备注提示',
            prop: 'remark_content',
            tips: '备注表单的说明文字',
            isHide: !(
              this.query.type == 1 && this.query.form_info.includes('remark')
            ),
          },
          {
            label: '活动支付',
            prop: 'is_pay',
            type: 'radio',
            list: this.openRedPacketList,
            isHide: this.query.type != 1,
          },
          {
            label: '支付金额',
            prop: 'pay_money',
            type: 'number',
            precision: 2,
            tips: `${this.translateTitle(
              '请填写支付金额'
            )},${this.translateTitle('填写格式如')}:10.00,${this.translateTitle(
              '则该活动应支付金额为'
            )}10.00${this.translateTitle(this.config.global_money_china)}`,
            isHide: this.query.type != 1,
          },
          {
            label: '详细内容',
            prop: 'content',
            type: 'richTextEditor',
          },
        ]
      },
    },
    created() {
      this.getLabelList()
    },
    methods: {
      translateTitle,
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 获取标签列表
      getLabelList() {
        if (this.labelList.length === 0) {
          for (let key of Object.keys(this.config.icons.labels)) {
            const item = { id: key, label: this.config.icons.labels[key] }
            this.labelList.push(item)
          }
          this.labelList.unshift({ id: '', label: '无' })
        }
      },
      // 发布
      async handleRelease(type, value) {
        const params = Object.assign({}, this.query)
        if (!params.pay_money || params.pay_money < 0.01) {
          return this.$message.error(
            `${this.translateTitle('请填写支付金额，并且不能小于')} 0.01`
          )
        }
        // 限制更多说明项目
        if (params.type == 1) {
          const extra_info = params.extra_info
          if (extra_info.length) {
            for (let i = 0; i < extra_info.length; i++) {
              if (!extra_info[i].title) {
                return this.$message.error(
                  `${this.translateTitle('请填写更多说明项目标题')}`
                )
              } else if (!extra_info[i].content) {
                return this.$message.error(
                  `${this.translateTitle('请填写更多说明项目内容')}`
                )
              }
            }
          }
        } else {
          params.extra_info = []
        }

        if (!params.id) delete params.id
        if (type === 'save') {
          params.publish_time = params.publish_time / 1000
        } else if (type === 'now') {
          params.publish_time = new Date().getTime() / 1000
        } else if (type === 'null') {
          params.publish_time = null
        } else if (type === 'timing') {
          params.publish_time = value / 1000
        }
        for (let item of this.timeKey) {
          if (params[item]) {
            params[item] = params[item] / 1000
          }
        }
        let obj = {}
        for (let item of this.checkItem) {
          if (params.form_info.includes(item)) {
            obj[item] = true
          } else {
            obj[item] = false
          }
        }
        obj.remark_content = params.remark_content
        params.form_info = JSON.stringify(obj)
        params.extra_info = JSON.stringify(params.extra_info)
        delete params.remark_content
        const res = await activitySave(params)
        if (res.success) {
          await this.delVisitedRoute(this.$route.path)
          this.$router.go(-1)
          this.$message.success(`${this.translateTitle('保存成功')}`)
        } else {
          this.$message.error(res.msg)
        }
      },
      // 打开定时发布时间
      hanldeTiming() {
        this.$refs.releaseDialog.handleOpen()
      },
      // 上传图片
      handleImageUrl(prop, data) {
        this.$emit('handleImageUrl', prop, data)
      },
      // 生成随机数
      createRandom() {
        this.$emit('createRandom')
      },
      // 新增更多说明
      handleCreate() {
        this.$emit('handleCreate')
      },
      // 删除更多说明
      hanldeRemove(index) {
        this.$emit('hanldeRemove', index)
      },
      // 地址
      handleLink(link) {
        this.$emit('handleLink', link)
      },
      // 打开选择地址弹框
      handleCheckLink() {
        this.$refs.checklink.handleOpen()
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.activity';
  #{$base}-container {
    #{$base}-btn {
      margin: 40px 0 80px 100px;
    }
    .el-divider--horizontal {
      margin: 0;
    }
    .m-r-10 {
      margin-right: 10px;
    }
    .m-b-10 {
      margin-bottom: 10px;
    }
  }
</style>
